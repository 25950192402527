import React, { useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import T from "prop-types"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import * as blobs from "blobs/v2"
import styles from "./Team.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import * as Icons from "components/Icons"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const Member = (props) => {
  const [isBioShown, showBio] = useState(false)
  const onShowBio = useCallback(() => showBio(!isBioShown), [isBioShown])
  const [blob, setBlob] = useState(
    blobs.svgPath({ seed: props.index, extraPoints: 4, randomness: 1, size: 200 })
  )
  const onChange = () =>
    setBlob(
      blobs.svgPath({
        seed: props.index + Math.round(Math.random() * 100),
        extraPoints: 4,
        randomness: 1,
        size: 200,
      })
    )
  const springProps = useSpring({ d: blob })

  // useEffect(() => {
  //   if (window.innerWidth <= 640) document.body.style.overflow = isBioShown ? "hidden" : ""
  // }, [isBioShown])

  return (
    <li className={styles.member}>
      <div
        className={styles.photo}
        role="button"
        tabIndex="-1"
        onMouseEnter={onChange}
        onMouseLeave={onChange}
      >
        <svg viewBox="0 0 200 200">
          <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4ebfae" />
            <stop offset="1" stopColor="#00aeca" />
          </linearGradient>
          <animated.path {...springProps} fill="url(#gradient)" />
        </svg>
        <div>
          {props.photo ? (
            <Img
              loading="lazy"
              objectFit="cover"
              alt={props.name}
              title={props.name}
              fluid={props.photo.childImageSharp.fluid}
            />
          ) : null}
        </div>
      </div>
      <h2>{props.name}</h2>
      <h3>{props.position}</h3>
      <button type="button" onClick={onShowBio}>
        {isBioShown ? "Close Bio" : "View Bio"}
      </button>
      {isBioShown ? (
        <div className={styles.bio}>
          <div
            onKeyPress={() => {}}
            tabIndex="-1"
            role="button"
            className={styles.bioclose}
            onClick={onShowBio}
          >
            {Icons.X}
          </div>
          <p>
            {props.bio} <strong>- {props.location}</strong>
          </p>
        </div>
      ) : null}
      {props.linkedin ? (
        <a
          className={styles.linkedin}
          href={props.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          {Icons.Linkedin}
        </a>
      ) : null}
    </li>
  )
}

Member.propTypes = {
  photo: T.shape({}).isRequired,
  name: T.string.isRequired,
  position: T.string.isRequired,
  bio: T.string.isRequired,
  location: T.string.isRequired,
  linkedin: T.string,
}

// eslint-disable-next-line
export const TeamPage = ({ data, ...props }) => (
  <Layout {...props} {...data}>
    <Container className={altStyles.wrapper}>
      <div className={styles.col}>
      <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>{data.title}</Title>
        <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
        <Link className={altStyles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
      </div>
      <div className={`${styles.col} ${altStyles.image}`}>
        {data.image && data.image.extension === "svg" ? (
          <img alt={data.title} title={data.title} src={data.image.publicURL} width="480" height="300" />
        ) : data.image && data.image.childImageSharp ? (
          <Img
            loading="lazy"
            objectFit="contain"
            key={data.title}
            alt={data.title}
            title={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        ) : null}
      </div>
    </Container>
    <div className={altStyles.grayBackground}>
      <Container className={styles.memberswrap}>
        <ul className={styles.members}>
          {data.members.map((member, index) => (
            <Member key={index} index={index} {...member} />
          ))}
        </ul>
      </Container>
    </div>
    <div className={altStyles.regularBackground} id="contact-form">
      <Container>
      <div className={altStyles.titleContainer}>
          <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span>Learn more about how OpsGuru can help you with cloud adoption through our breadth of services.</TitleBlock>
        </div>
        <HubspotForm id="b586e1c3-2aa2-4cce-815e-a8fb546726bb" portalId="6237410" />
      </Container>
    </div>
  </Layout>
)

TeamPage.propTypes = {
  data: T.shape({
    title: T.string.isRequired,
    description: T.string.isRequired,
    members: T.arrayOf(T.object.isRequired).isRequired,
  }).isRequired,
}

// eslint-disable-next-line
export const TeamPageWrapper = (props) => (
  <TeamPage {...props} data={props.data.markdownRemark.frontmatter} />
)

export default TeamPageWrapper

export const pageQuery = graphql`
  query TeamPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        bannerButtonText
        bannerButtonUrl
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 700, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        members {
          photo {
            childImageSharp {
              fluid(maxWidth: 200, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          name
          position
          bio
          location
          linkedin
        }
      }
    }
  }
`
